.textColor1 {
    color: #023047;
}

.textColor2 {
    color: #505664;
}

.textColor3 {
    color: #62697B;
}

.textColor4 {
    color: #ACB0B9;
}

.textColor5 {
    color: #045C87;
}

.fontSize1 {
    font-size: 25px;
}

.fontSize2 {
    font-size: 16px;
}

.fontSize3 {
    font-size: 30px;
}

.fontSize4 {
    font-size: 18px;
}

.fontSize5 {
    font-size: 28px;
}

.fontSize6 {
    font-size: 14px;
}

.fontSize7 {
    font-size: 30px;
}

.fontSize8 {
    font-size: 22px;
}

.fontSize9 {
    font-size: 24px;
}

.fontSize10 {
    font-size: 20px;
}


.loginBgColor {
    background-color: #023047;
}

.fontFamily1 {
    font-family: 'Source Sans Pro';
}

.fontFamily2 {
    font-family: 'Roboto';
}

.fontWeight1 {
    font-weight: 600;
}

.fontWeight2 {
    font-weight: 400;
}

.fontWeight3 {
    font-weight: 900;
}

.fontWeight4 {
    font-weight: 700;
}

.InputPaddingTop {
    padding-top: 25px;
}

.buttonSmall {
    font-size: 40px;
    background-color: #ffffff;
    color: #023047;
    height: 55px;
    width: 55px;
    box-shadow: 1px 1px 5px 2px rgb(0 0 0 / 10%);
}

/* .buttonMedium {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1); 
} */

.buttonMediumYesNo {
    padding: 5px 25px 5px 25px;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.buttonMediumBack {
    font-size: 25px;
    height: 55px;
    /* box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1); */
    background-color: #f6fbfd;
}

.flagButton {
    background-color: #F7EEFE;
    border-radius: 4px;
    padding: 0px 15px 0px 15px;
    font-weight: 600;
    color: #7A28BA;
    align-items: center;
    justify-content: center;
    /* width: 90px; */
    height: 40px;
    display: flex;
}

.mantine-Navbar-root {
    overflow: auto;
}

.yesNoBtn:hover {
    background-color: #ffffff;
}

/*InputControl Table */
hh

/*Text size and color*/
.tableMainCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
}

.setCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
}

.outerCard {
    justify-content: space-between;
    gap: 64px;
    width: 100%;
}

.imageTextContainer {
    padding: 1%;
    min-width: 70%;
    gap: 16px;
}

.btnContainer {
    flex-grow: 1;
    justify-content: space-evenly;
    gap: 16px;
}


.textContainer {
    display: flex;
    flex-direction: column
}


.tableMainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* gap: 1.05vw; */
    margin-bottom: 2vw;
}

.tableHeadrBackground {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2%;
    gap: 10px;
    background: #DEF1FD;
    border-radius: 3px 3px 0px 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-right: 8%;
    justify-content: space-between
}

.tableHeaderRight {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
}

.tableHeaderLeft {


    display: flex;
    align-items: center;
    flex-grow: 15;
}

/* Start and Delete Button inside table inputControl*/


/* .tableEditbtn {
    background-color: #023047;
    color: #ffffff;
} */

.tableAddAnotherBtn {
    background-color: #ffffff;
    color: #023047;
}

/*Input Type Date*/
.datePickerContainer {
    display: flex;
    flex-direction: row;
    width: 49%;
    gap: 1.63vw;
    margin-top: 1.7vw;
    padding-right: 1%
}

/*Will Flow Process Main COnrtainer*/
.mainContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
}

/*BreadCrumb Style*/
.breadCrumbMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2%
}

/*Login, Logout, OTP screen, Forgot Password*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ACB0B9;
    opacity: 1;
    /* Firefox */
}

.resendOtpContainer {
    display: flex;
    color: #023047;
    padding-top: 10px;
    width: 100%;
    justify-content: center;
}

.ForgotPassWord {
    display: flex;
    color: #023047;
    padding-top: 10px;
    width: 100%;
    justify-content: center;
}

.otp-counter {
    color: green;
    font-weight: bold;

}

.resendText {
    color: #023047;
    font-weight: 600;
}

.resendOTPBtn {
    color: #203047;
}

.resendOTPBtn:disabled {
    color: #90abd0;
    cursor: default;
}



.title1 {
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.title2 {
    padding-top: 0.3vw;
    padding-bottom: 0.5vw;
    /* font-weight: 600; */
}

/* .mantine-PasswordInput-innerInput {
    height: 50px;
    font-size: 20px;
} */

input[type="password"] {
    font-size: 20px;
    height: 45px;
}


/*Sign In , SignUp page*/
.mainBody {
    /* overflow: hidden; */
    position: relative;
    width: 100%;
}

/*OTP Screen Input boxes*/
.otpbox {
    width: 40px;
    height: 40px;
    border: 1px solid #023047;
    border-radius: 4px;
    text-align: center;
    padding: 2px;
}

.mantine-Grid-root {
    margin: 0
}

.setFooterText {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);
    margin-left: auto;
    margin-right: 0;
    padding: 15px 0px 15px 0px;

}

.setFooterText2 {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 0;
    padding: 8px 0px 15px 0px;
    margin-top: 50px;
    margin-right: 300px;
}


/*Info Card Information Binder*/
.cardIcon {
    padding: 15px
}

.mantine-Button-label {
    text-transform: none;
}

.selectInput {
    display: flex;
    justify-content: space-between;
}

.InfoIcon {
    border-radius: 50%;
    font-size: 18;
}

.cardBorderRad {
    border: 1px solid #e0dfdfa6;
  margin-bottom: 5px;
    border-radius: 5px;
}



.cardBorderRad2 {
    border: 1px solid #e0dfdfa6;
    margin: 5px 5px 5px 5px;
    border-radius: 4px;
    padding: 16px;
}



.cardBorder .cardBorderRad:first-child {
    border: 1px solid #e0dfdfa6;
    margin-bottom: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cardBorder .cardBorderRad:first-child:hover {
    border-color: #045C87;
}

.cardBorderRad:hover {
    border-color: #045C87 !important;
}

.cardBorderRad2:hover {
    border-color: #045C87 !important;
}

.cardBorder:first-of-type {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.menuButtonText,
.mantine-NavLink-label {
    /* style={{ color: '#62697B', fontSize: 20, paddingLeft: 10, }} */
    color: #62697B;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Source Sans Pro';

}

.menuButtonIcon {
    color: #62697B;
    font-size: 24px;
}

.menuButtonText:hover,
.mantine-NavLink-label:hover {
    color: #023047;
}

.menuButtonIcon:hover {
    color: #023047;
}

.sideMenuItem:hover {
    color: #023047;
}

.breadCrumbMainText1 {
    font-size: 20px;
    font-weight: 600;
    color: #023047;
}

.breadCrumbMainText2 {
    font-size: 20px;
    font-weight: 600;
    color: #ACB0B9;
}

.testaTor {
    background-color: #ECF9F3;
    border-radius: 4px;
    padding: 8px;
    font-weight: 600;
    color: #239E69;

}

.customFooter {
    left: 270px;
}

.mantine-NavLink-root[data-active] {
    color: #023047;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.cardHover {
    cursor: pointer;
}

/* .mantine-Modal-title{
    font-size: 30 ;
} */
.mantine-Modal-close {
    width: 45px;
    height: 45px;

}

.mantine-Modal-close svg {
    width: 40px;
    height: 40px;
}

.mantine-Modal-close svg:hover {
    color: red;
}

.profile-image img {
    border-radius: 50%;
}

.mantine-Accordion-item {
    background-color: #fff;
    border: 1px solid #E9ECEF;

}

.mantine-Accordion-item[data-active] {
    border-color: #E9ECEF;
}

.alignCheckbox {
    display: flex;
    align-items: center;
}

.innerCheckbox1 {
    margin-left: 6px;
}

.innerCheckbox2 {
    margin-left: 10px;
}

.mantine-Notification-root {
    border: 1px solid #E9ECEF;
}

.textInfo {
    color: #919497;
    font-size: 14px;
}

.TestChek {
    margin-left: 0px;
}

.mantine-Badge-inner {
    text-transform: capitalize;
}

.viewAs {
    background-color: #239e6926;
    color: #239E69;
    padding: 5px;
    border-radius: 5px;
}

.ViewMock {
    background-color: #8F00FF15;
    color: #8104e5f7;
    padding: 0px;
    border-radius: 5px 5px 0px 0px;
}

.messageModal .mantine-Modal-title {
    font-size: 25px;
    color: #023047;
}

.iconBg {
    background-color: #f6fbfd !important;
    color: #868e96 !important;
}


.mantine-PasswordInput-label {
    font-size: 20px;
}



.mantine-Button-label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
}

.title1 {
    padding-bottom: 0.6vw;
}

.title2 {
    padding-bottom: 1.5vw;
}

.accessControlCard {
    padding-top: 10px;
    /* padding-left: 80px;
    padding-right: 25px; */
}

.accessCtrlCardTitle {
    padding-top: 20px;
    padding-left: 10px;
}

.accessCtrlCardMock {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

/* .mantine-AppShell-root{
    min-width: 768px ;
    overflow: auto;
} */
.mantine-AppShell-root {
    min-width: 768px;
    overflow: auto;
}

.mantine-AppShell-body {
    min-width: 768px;
    overflow: auto;
}
.mantine-Stepper-stepLabel{
    font-size: 20px;
}
.customButton{
    color: #203047;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    font-family: 'Source Sans Pro';
    font-weight: 400,
}
.customButton:hover{
    background-color: #ffffff00;
    cursor: pointer;
    text-decoration: underline;
}
.footerLink:hover{
    cursor: pointer;
}
.headerLogo{
    max-width: 230px;
}
.headerLogo:hover{
    cursor: pointer;
}

.setRadioBtn .mantine-Group-root{
    display: flex;
    justify-content: space-around;
}
/* '@media (max-width: 1600px)': {
    font-size: 18px;
    lineHeight: "18px"
},
'@media (max-width: 1280px)': {
    font-size: 18px;
    lineHeight: "16px"
},
'@media (max-width: 1200px)': {
    fontSize: 16,
    lineHeight: "16px"
},
'@media (max-width: 992px)': {
    fontSize: 12,
    lineHeight: "12px"
},
'@media (max-width: 768px)': {
    fontSize: 18,
    lineHeight: "18px"
}, */


/* .customLink {
    text-decoration: none;
    display: inline-block;
    position: relative;
  }
  
  .customLink::after {
    content: '';
    display: inline-block;
    width: 100%;
    left: 0;
    height: 1px;
    position:absolute;
    background-color: rgb(0, 0, 238);
    bottom: -2px;
  } */
  .mantine-Input-disabled{
    background-color: #fdfdfd  !important;
    color: #000000  !important;
  }
  a.customLink {
    padding: 0px 10px;
    word-wrap: normal;
    display: inline-block;
  }
  .mantine-Checkbox-input:disabled{
    background-color: #228be6 !important;
    border-color: #228be6 !important;
  }
.mantine-Select-dropdown{
    min-width: 180px !important;
    left:0
}

.tableBorder{
    border-bottom: 1px solid #E9ECEF !important;
    text-decoration: none;
    font-size: 18px;
    font-family: Roboto;
    color: #023047;
    font-weight: 400;
}

.accordianBorder{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #E9ECEF;
}
@media only screen and (max-width: 3840px) {
    .permissionBtn{
        height: 55px !important;
    }
    .mantine-Button-label{ 
       font-size: 14px;
       text-align: center;
    }
    .setBtnText{
        display: flex;
        flex-direction: row;
    }
}
@media only screen and (max-width: 2560px) {
    .mantine-Button-label{ 
       font-size: 20px;
       text-align: center;
    }
}
@media only screen and (max-width: 1920px) {
    .mantine-Button-label{ 
       font-size: 20px;
       text-align: center;
    }
}
@media only screen and (max-width: 1600px) {
    .mantine-PasswordInput-label {
        font-size: 22px;
    }
 
}

@media only screen and (max-width: 1440px) {
    .mantine-PasswordInput-label {
        font-size: 18px;
    }

    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 18px;
        height: 35px;
    }
    .mantine-Button-root {
        height: 35px;
    }
}

@media only screen and (max-width: 1400px) {
    .accessControlCard {
        padding-left: 15px;
        padding-right: 15px;
    }

    .accessCtrlCardTitle {
        padding-top: 0px;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center
    }

    .accessCtrlCardMock {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .mantine-PasswordInput-label {
        font-size: 18px;
    }

    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 18px;
        height: 35px;
    }
    .mantine-Button-root {
        height: 35px;
    }
}

@media only screen and (max-width: 1366px) {
    .cardIcons>svg {
        width: 40px;
        height: 40px;
    }
    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 18px;
        height: 35px;
    }
}

@media only screen and (max-width: 1280px) {
    .title1 {
        padding-bottom: 0.4vw;
    }

    .title2 {
        padding-bottom: 0.6vw;
    }

    .mantine-Button-root {
        height: 35px;
    }

    .mantine-Button-inner {
        font-size: 15px;
    }

    .mantine-Button-label {
        font-size: 14px;
    }

    .fontSize3 {
        font-size: 25px;
    }

    .mantine-PasswordInput-label,
    .breadCrumbMainText1,
    .breadCrumbMainText2 {
        font-size: 16px;
    }

    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 16px;
        height: 30px;
    }

    .InputPaddingTop {
        padding-top: 10px;
    }

    .fontSize8,

    .menuButtonText {
        font-size: 16px;
    }

    .fontSize4 {
        font-size: 14px;
    }

    .fontSize1,
    .fontSize5,
    .fontSize3 {
        font-size: 20px;
    }

    .fontSize10 {
        font-size: 16px;
    }


    .testaTor {
        padding: 4px;
    }

    .svgIcon svg {
        width: 20px;
        height: 20px;
    }

    .menuButtonText,
    .mantine-NavLink-label {
        font-size: 16px;

    }

    .mantine-AppShell-main {
        padding-left: 286px;
    }

    .setFooterText {
        width: calc(100% - 270px);
    }

    .cardIcons>svg {
        width: 40px;
        height: 30px;
    }
    .mantine-Stepper-stepLabel{
        font-size: 16px;
    }
    .mantine-Button-root {
        height: 30px;
    }
  
}

@media only screen and (max-width: 1200px) {
    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 16px;
        height: 30px;
    }

    .mantine-PasswordInput-label {
        font-size: 15px;
    }

    .mantine-Button-root {
        height: 30px;
    }

    .mantine-Button-label {
        font-size: 20px;
        line-height: 20px;
    }

    .title1 {
        padding-bottom: 0.4vw;
    }

    .title2 {
        padding-bottom: 1.0vw;
    }

    .cardIcons>svg {
        width: 50px
    }

}
@media only screen and (max-width: 1176px) {
  
}
@media only screen and (max-width: 1024px) {
    .cardIcons>svg {
        width: 50px
    }
}

@media only screen and (max-width: 992px) {
    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 16px;
        height: 30px;
    }

    .mantine-PasswordInput-label {
        font-size: 12px;
        line-height: 16px,
    }

    .mantine-Button-root {
        height: 35px;
    }

    .mantine-Button-label {
        font-size: 20px;

    }

    .title1 {
        padding-bottom: 0.4vw;
    }

    .title2 {
        padding-bottom: 0.6vw;
    }
 

}

@media only screen and (max-width: 768px) {
    input[type="password"],
    .mantine-PasswordInput-input >input{
        font-size: 18px;
        height: 45px;
    }

    .mantine-PasswordInput-label {
        font-size: 18px;

    }

    .mantine-Button-root {
        height: 45px;

    }

    .mantine-Button-label {
        font-size: 18px;
    }

    .formContainer {
        margin-top: 50px;
        padding-bottom: 50px;
    }

    /*for scroll after 768px*/
    /* .cardWidth {
        width: 436px;
    } */

    .actionBtnWidth {
        width: 127px;
    }

    .trashBtnWidth {

        width: 36px;
    }

    .textWidth {
        width: 254px;
    }

    /*for header*/
    .mantine-AppShell-main {
        padding-left: 16px;
    }

    .setFooterText {
        width: calc(100% - 0px);
        margin-left: 0;
        margin-right: 0;
    }

    .setFooterText2 {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 576px) {

    /* .cardWidth {
        width: 436px;
    } */
    .innerCardWidth {
        max-width: 90%;
    }

    .actionBtnWidth {
        max-width: 20%
    }

    .trashBtnWidth {

        max-width: 10%;
    }

    .textWidth {
        max-width: 80%;
    }

    .headerBurger {
        max-width: 5%;
    }

    .headerText {
        max-width: 25%;
    }

    .headerSearch {
        max-width: 45%;
    }

    .headerIcons {
        max-width: 25%;
    }
}